import services from "@/http";
import * as types from "./mutation-types";

export const ActionQueueUpdate = ({ dispatch }, payload) => {
  return services.updateQueues.update(payload).then((res) => {
    dispatch("ActionSetUpdateQueue", res.data);
  });
};

export const ActionSetUpdateQueue = ({ commit }, payload) => {
  commit(types.SET_EDITEDQUEUE, payload);
};
