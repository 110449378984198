import store from "../../../store";

export default [
  {
    path: "/registerServices",
    name: "registerServices",
    component: () => import(/*webpackChunckName: "registerServices" */ "./RegisterServices.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/hasRole"] === "admin") {
        next();
      } else {
        next({ name: "attendance" });
      }
    },
  },
];
