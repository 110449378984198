import { store as auth } from "@/modules/auth";
import { store as panelAttendance } from "@/pages/panelAttendance";
import { store as registerServices } from "@/pages/services/registerServices";
import { store as searchServices } from "@/pages/services/searchServices";
import { store as updateServices } from "@/pages/services/updateServices";
import { store as searchQueues } from "@/pages/queues/searchQueues";
import { store as updateQueues } from "@/pages/queues/updateQueues";
import { store as registerQueues } from "@/pages/queues/registerQueues";
import { store as report} from "@/pages/report"
import { store as guiches } from "@/pages/queues/guiches"

export default {
  auth,
  registerServices,
  searchServices,
  updateServices,
  panelAttendance,
  searchQueues,
  updateQueues,
  registerQueues,
  report,
  guiches
};
