import { routes as auth } from "../modules/auth";
import { routes as home } from "../pages/home";
import { routes as attendance } from "../pages/panelAttendance";
import { routes as searchServices } from "../pages/services/searchServices";
import { routes as registerServices } from "../pages/services/registerServices";
import { routes as searchQueues } from "../pages/queues/searchQueues";
import { routes as registerQueues } from "../pages/queues/registerQueues";
import { routes as report } from "../pages/report";
import { routes as guiches } from "../pages/queues/guiches"

export const routes: Array<any> = [
  ...auth,
  ...home,
  ...attendance,
  ...searchServices,
  ...registerServices,
  ...searchQueues,
  ...registerQueues,
  ...report,
  ...guiches
];
