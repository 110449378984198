import * as types from "./mutation-types";

export default {
  [types.SET_MESSAGE](state, payload) {
    state.msgOnDisconnect = payload;
  },
  [types.SET_CONNECTED_GUICHES](state, payload) {
    state.connectedGuiches = payload;
  }
};
