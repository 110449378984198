import { services as auth } from "@/modules/auth";
import { services as panelAttendance } from "@/pages/panelAttendance";
import { services as registerServices } from "@/pages/services/registerServices";
import { services as searchServices } from "@/pages/services/searchServices";
import { services as updateServices } from "@/pages/services/updateServices";
import { services as searchQueues } from "@/pages/queues/searchQueues";
import { services as updateQueues } from "@/pages/queues/updateQueues";
import { services as registerQueues } from "@/pages/queues/registerQueues";
import { services as report } from "@/pages/report";
import { services as guiches } from "@/pages/queues/guiches";

export default {
  auth,
  registerServices,
  searchServices,
  updateServices,
  panelAttendance,
  searchQueues,
  updateQueues,
  registerQueues,
  report,
  guiches
};
