import * as types from "./mutation-types";

export default {
  [types.SET_REPORT_FILTER_INFOS](state, payload) {
    state.reportsFilters = payload;
  },
  [types.SET_REPORT_OF_ATTENDANCES](state, payload) {
    state.reportOfAttendances = payload;
  },
  [types.SET_EMPLOYEE_REPORT](state, payload) {
    state.employeeReport = payload;
  },
  [types.SET_SERVICES_REPORT](state, payload) {
    state.servicesReport = payload;
  }
};
