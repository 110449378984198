export default {
  callNextPassword: {
    method: "post",
    url: "waiting-attendances/call-next-attendance",
  },

  callAbsentAttendance: {
    method: "post",
    url: "waiting-attendances/absent-attendance",
  },

  cancelPassword: {
    method: "put",
    url: "waiting-attendances/cancel-attendance",
  },

  proceedAttendance: {
    method: "put",
    url: "waiting-attendances/attendance-in-progress",
  },

  concludeAttendance: {
    method: "put",
    url: "waiting-attendances/attendance-completed"
  },

  listGuiches: {
    method: "get",
    url: "guiche",
  },

  listCurrentPasswords: {
    method: "get",
    url: "waiting-attendances"
  }
};
