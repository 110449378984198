import services from "@/http";
import * as types from "./mutation-types";


export const ActionCallNextPassword = ({ dispatch }, payload) => {
  return services.panelAttendance.callNextPassword(payload).then((res) => {
    dispatch("ActionSetPassword", res.data);
  });
};

export const ActionCallAbsentAttendance = ({ dispatch }, payload) => {
  return services.panelAttendance.callAbsentAttendance(payload).then((res) => {
    dispatch("ActionSetPassword", res.data);
  })
}

export const ActionSetPassword = ({ commit }, payload) => {
  commit(types.SET_ATTENDANCE, payload);
};

export const ActionCancelPassword = (context, payload) => {
  services.panelAttendance.cancelPassword(payload);
};

export const ActionProceedPassword = (context, payload) => {
  services.panelAttendance.proceedAttendance(payload).catch(err => alert(err.data?.message || "Ocorreu um erro ao prosseguir o atendimento."));
};

export const ActionConcludeAttendance = (context, payload) => {
  services.panelAttendance.concludeAttendance(payload);
}

export const ActionListGuiches = ({ commit }) =>
  services.panelAttendance.listGuiches().then((res) => {
    const allGuiches = res.data;
    const availableGuiches = allGuiches.map((queue) => {
      if (queue.connectedWithUser === false) {
        return queue.name;
      }
    });
    commit(types.SET_GUICHESPANEL, availableGuiches);
  });

export const ActionListCurrentPasswords = ({ commit }) =>
  services.panelAttendance.listCurrentPasswords().then((res) => {
    const allCurrentPasswords = res.data.map((attendance) => {

      const password = {
        password: attendance.password,
        absent: attendance.absentOnFirstCall ? true : false,
        dateStart: attendance.attendance.date_start
      }
      return password
    })
    commit(types.SET_CURRENTPASSWORDS, allCurrentPasswords);
  })
