import { setBearerToken, setBearerRole, setBearerUser } from "@/http";

export const setHeaderToken = (token) => setBearerToken(token);
export const getLocalToken = () => sessionStorage.getItem("token");
export const deleteLocalToken = () => sessionStorage.removeItem("token");
export const setLocalToken = (token) => sessionStorage.setItem("token", token);

export const setHeaderRole = (role) => setBearerRole(role);
export const getLocalRole = () => sessionStorage.getItem("role");
export const deleteLocalRole = () => sessionStorage.removeItem("role");
export const setLocalRole = (role) => sessionStorage.setItem("role", role);

export const setHeaderUser = (userLogin) => setBearerUser(userLogin);
export const getLocalUser = () => JSON.parse(sessionStorage.getItem("user"));
export const deleteLocalUser = () => sessionStorage.removeItem("user");
export const setLocalUser = (user) => sessionStorage.setItem('user', JSON.stringify(user));

export const getLocalGuiche = () => JSON.parse(sessionStorage.getItem("guiche"));
export const deleteLocalGuiche = () => sessionStorage.removeItem("guiche");
export const setLocalGuiche = (guiche) => sessionStorage.setItem("guiche", JSON.stringify(guiche));