export default {
  listInfosForReport: {
    method: "get",
    url: "reports/list-infos"
  },
  listReportOfAttendance: {
    method: "post",
    url: "reports/attendance"
  },
  listEmployeeReport: {
    method: "post",
    url: "reports/employee"
  },
  listServicesReport: {
    method: "post",
    url: "reports/service"
  }

};
