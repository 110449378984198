import services from "@/http";
import * as types from "./mutation-types";

export const ActionSubmit = ({ dispatch }, payload) => {
  return services.registerServices.register(payload).then((res) => {
    dispatch("ActionSetRegister", res.data.servicedto);
  });
};

export const ActionSetRegister = ({ commit }, payload) => {
  commit(types.SET_SERVICEDTO, payload);
};
