import * as types from "./mutation-types";

export default {
  [types.SET_ATTENDANCE](state, payload) {
    state.attendance = payload;
  },
  [types.SET_GUICHESPANEL](state, payload) {
    state.guichesPanel = payload;
  },
  [types.SET_CURRENTPASSWORDS](state, payload) {
    state.currentPasswords = payload;
  }
};
