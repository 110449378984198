import store from "../../../store";

export default [
  {
    path: "/searchServices",
    name: "searchServices",
    component: () => import(/* webpackChunkName: "searchServices" */ "./SearchServices.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/hasRole'] === 'admin') {
        next();
      } else {
        next({ name: "attendance"});
      }
    }
  },
];
