import services from "@/http";
import * as types from "./mutation-types";

export const ActionFindQueuesList = ({ commit }) =>
  services.searchQueues.findQueueslist().then((res) => {
    commit(types.SET_QUEUESLIST, res.data);
  });

export const ActionDeleteQueue = (context, payload) => {
  services.searchQueues.deleteQueue({ id: payload.id });
};
