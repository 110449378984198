import store from "../../store";

export default [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "login" */ "./Home.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/hasRole'] === 'admin') {
        next();
      } else {
        next({ name: "attendance"});
      }
    }
  },
];
