import services from "@/http";
import * as types from "./mutation-types";

export const ActionDisconnectUserFromGuiche = ({ dispatch }, payload) => {
  return services.guiches.disconnectUserFromGuiche(payload).then((res) => {
    dispatch("ActionSetMessage", res.data);
  });
};

export const ActionSetMessage = ({ commit }, payload) => {
  commit(types.SET_MESSAGE, payload);
};

export const ActionListConnectedGuiches = ({ dispatch }) => {
  return services.guiches.findGuiches().then((res) => {
    const allGuiches = res.data.map((guiche) => {
      return {
        name: guiche.name,
        user: guiche.userConnected || "nenhum",
        useStatus: guiche.useStatus ? "Em atendimento" : "Livre",
        connected: guiche.connectedWithUser
      }
    })
    
    const guiches = allGuiches.filter((guiche) => {
      return guiche.connected === true;
    })

    if (guiches.length === 0) {
      dispatch("ActionSetConnectedGuiches", [
        {
          name: "nenhum",
          user: { name: "nenhum" },
          useStatus: "nenhum",
        }
      ]);
      return;
    }
    dispatch("ActionSetConnectedGuiches", guiches);

  });
};

export const ActionSetConnectedGuiches = ({ commit }, payload) => {
  commit(types.SET_CONNECTED_GUICHES, payload);
};
