import store from "../../store";

export default [
  {
    name: 'attendance',
    path: '/attendance',
    component: () => import(/*webChunckName? "attendance" */ './PanelAttendance.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/hasRole'] == 'atendimento' || 'admin') {
        next();
      } else {
        next({ name: "login" });
      }
    }
  }
]