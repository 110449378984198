import store from "../../../store";

export default [
  {
    path: "/searchQueues",
    name: "searchQueues",
    component: () => import(/*webpackChunckName: "searchQueues" */ "./SearchQueues.vue"),
    beforeEnter: (to, from, next) => {
      if (store.getters["auth/hasRole"] === "admin") {
        next();
      } else {
        next({ name: "attendance" });
      }
    },
  },
];
