import Vue from "vue";
import VueResource from "vue-resource";
import services from "./services";
import interceptors from "./interceptors";
import getEnv from "../configs/env";

Vue.use(VueResource);

const http = Vue.http;

http.options.root = getEnv("VUE_APP_API");

http.interceptors.push(interceptors);

Object.keys(services).map((service) => {
  services[service] = Vue.resource("", {}, services[service]);
});

const setBearerToken = (token) => {
  http.headers.common["Authorization"] = `Bearer ${token}`;
};

const setBearerRole = (role) => {
  http.headers.common["role"] = role
}

const setBearerUser = (userLogin) => {
  http.headers.common["user_login"] = userLogin;
}

export default services;
export { http, setBearerToken, setBearerRole, setBearerUser };
