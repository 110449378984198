import services from "@/http";
import * as types from "./mutation-types";

export const ActionFindServicesList = ({ commit }) =>
  services.searchServices.findServiceslist().then((res) => {
    commit(types.SET_SERVICESLIST, res.data);
  });

export const ActionDeleteService = (context, payload) =>
  services.searchServices.deleteService({ id: payload.id });
