import services from "@/http";
import * as storage from "../storage";
import * as types from "./mutations-types";

export const ActionDoLogin = ({ dispatch }, payload) => {
  return services.auth.login(payload).then((res) => {
    dispatch("ActionSetUser", res.data.user);
    dispatch("ActionSetToken", res.data.token);
    dispatch("ActionSetRole", res.data.role);
  });
};

export const ActionCheckToken = ({ dispatch, state }) => {
  if (state.token) {
    return Promise.resolve(state.token);
  }

  const token = storage.getLocalToken();
  const role = storage.getLocalRole();
  const user = storage.getLocalUser();
  const guiche = storage.getLocalGuiche();

  if (!token || !role || !user) {
    return Promise.reject(new Error("Token Inválido"));
  }

  dispatch("ActionSetUser", user);
  dispatch("ActionSetRole", role);
  dispatch("ActionSetToken", token);
  dispatch("ActionSetGuiche", guiche);
  return dispatch("ActionLoadSession");
};

export const ActionSetUser = ({ commit }, payload) => {
  storage.setLocalUser(payload);
  storage.setHeaderUser(payload.login);
  commit(types.SET_USER, payload);
};

export const ActionSetRole = ({ commit }, payload) => {
  storage.setLocalRole(payload);
  storage.setHeaderRole(payload);
  commit(types.SET_ROLE, payload);
};

export const ActionSetToken = ({ commit }, payload) => {
  storage.setLocalToken(payload);
  storage.setHeaderToken(payload);
  commit(types.SET_TOKEN, payload);
};

export const ActionSignOut = ({ dispatch }) => {
  storage.setHeaderToken("");
  storage.deleteLocalToken();
  storage.deleteLocalRole();
  storage.deleteLocalUser();
  storage.deleteLocalGuiche();
  dispatch("ActionSetToken", "");
  dispatch("ActionSetRole", "");
  dispatch("ActionSetUser", {});
  dispatch("ActionSetGuiche", "");
};

//DEFINIÇÕES DO GUICHE

export const ActionConnectGuiche = ({ dispatch }, payload) => {
  return services.auth.connectGuiche(payload).then((res) => {
    dispatch("ActionSetGuiche", res.data.name);
  });
};

export const ActionSetGuiche = ({ commit }, payload) => {
  storage.setLocalGuiche(payload);
  commit(types.SET_GUICHE, payload);
};

export const ActionDisconnectGuiche = ({ dispatch }, payload) => {
  return services.auth.disconnectGuiche(payload).then((res) => {
    console.log(res.data)
    alert(res.data)
  });
};
