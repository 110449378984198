import services from "@/http";
import * as types from "./mutation-types";

export const ActionServiceUpdate = ({ dispatch }, payload) => {
  return services.updateServices.update(payload).then((res) => {
    dispatch("ActionSetUpdate", res.data);
  });
};

export const ActionSetUpdate = ({ commit }, payload) => {
  commit(types.SET_EDITEDSERVICE, payload);
};
