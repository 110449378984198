import store from "../../store";

export default [
  {
    name: 'report',
    path: '/report',
    component: () => import(/*webChunckName? "attendance" */ './Report.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/hasRole'] === "admin") {
        next();
      } else {
        next({ name: "report" });
      }
    }
  }
]