import services from "@/http";
import * as types from "./mutation-types";

export const ActionSubmitQueue = ({ dispatch }, payload) => {
  return services.registerQueues.register(payload).then((res) => {
    dispatch("ActionSetRegisterQueue", res.data.queue);
  });
};

export const ActionSetRegisterQueue = ({ commit }, payload) => {
  commit(types.SET_QUEUE, payload);
};
