import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./assets/scss/app.scss";
import '@mdi/font/css/materialdesignicons.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import JsonExcel from 'vue-json-excel';
import IdleVue from 'idle-vue';

const eventsHub = new Vue();

const limit_to_afk_two_hours = 2 * 60 * 60 * 1000;

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: limit_to_afk_two_hours, // 2 hours for afk
  startAtIdle: false
})
Vue.component("downloadExcel", JsonExcel);

window._Vue = new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
