import services from "@/http";
import * as types from "./mutation-types";

export const ActionServicesReportGenerate = ({ dispatch }, payload) => {
  return services.report.listServicesReport(payload).then((res) => {
    dispatch("ActionSetServicesReport", res.data);
  })
}
export const ActionSetServicesReport = ({ commit }, payload) => {
  commit(types.SET_SERVICES_REPORT, payload);
}

export const ActionEmployeeReportGenerate = ({ dispatch }, payload) => {
  return services.report.listEmployeeReport(payload).then((res) => {
    dispatch("ActionSetEmployeeReport", res.data);
  })
}
export const ActionSetEmployeeReport = ({ commit }, payload) => {
  commit(types.SET_EMPLOYEE_REPORT, payload);
}

export const ActionReportOfAttendanceGenerate = ({ dispatch }, payload) => {
  return services.report.listReportOfAttendance(payload).then((res) => {
    dispatch("ActionSetReportOfAttendance", res.data);
  });
};
export const ActionSetReportOfAttendance = ({ commit }, payload) => {
  commit(types.SET_REPORT_OF_ATTENDANCES, payload);
}

export const ActionListReportFilterInfos = ({ commit }) => {
  services.report.listInfosForReport().then((res) => {
    const listReportFilterInfos = res.data
    listReportFilterInfos.guiches.unshift("Todos");
    listReportFilterInfos.collaborators.unshift("Todos");
    listReportFilterInfos.services.unshift("Todos");
    const reports = [
      {
        type: "attendance",
        title: "RELATÓRIO INDIVIDUAL POR SENHA",
        description:
          "Nesta página os administradores podem gerar relatórios dos atendimentos.",
        filters: [
          {
            filter_name: "Status de conclusão dos atendimentos",
            dropdown: ["Concluído", "Cancelado", "Todos"],
            value: "Todos",
          },
          {
            filter_name: "Tipo dos atendimentos",
            dropdown: ["Convencional", "Preferencial", "Todos"],
            value: "Todos",
          },
          {
            filter_name: "Atendimentos ausentes",
            dropdown: ["Ausente", "Não ausente", "Todos"],
            value: "Todos",
          },
        ],
        fields: {
          "Senha": "password",
          "Nome do beneficiário": "beneficiaryName",
          "Serviços realizados": "providedServices",
          "Tipo do atendimento": "typeOfAttendance",
          "Status de finalização": "endStatus",
          "Tempo de atendimento": "attendanceExecutionTimeInMinutes",
          "Duração total do atendimento": "attendanceTotalTimeInMinutes",
          "Data de emissão": "dateOfInit",
          "Data da ausência": "dateOfAbsent",
          "Data da conclusão": "dateOfConclusion",
          "Data do cancelamento": "dateOfCancel",
          "Guichês chamadores": "callerGuiches",
          "Nome do atendente": "attendantName",
          "Relatório gerado no periodo": "dateInterval",
        }
      },
      {
        type: "employee",
        title: "RELATÓRIO DOS COLABORADORES",
        description:
          "Nesta página os administradores podem gerar relatórios dos colaboradores.",
        filters: [
          {
            filter_name: "Colaborador atendente",
            dropdown: listReportFilterInfos.collaborators,
            value: "Todos",
          },
          {
            filter_name: "Guichê atendente",
            dropdown: listReportFilterInfos.guiches,
            value: "Todos",
          },
        ],
        fields: {
          "Nome do colaborador": "name",
          "Total de atendimentos": "amountTotalCalledAttendances",
          "Total de atendimentos preferênciais": "amountOfPreferentialAttendances",
          "Total de atendimentos convencionais": "amountOfConventionalAttendances",
          "Total de atendimentos cancelados": "amountOfCancelledAttendances",
          "Total de atendimentos concluídos": "amountOfCompletedAttendances",
          "Tempo médio de atendimento": "averageOfTimeAttendanceInExecutionInMinutes",
          "Relatório gerado no periodo": "dateInterval",
        }
      },
      {
        type: "service",
        title: "RELATÓRIO DOS SERVIÇOS",
        description:
          "Nesta página os administradores podem gerar relatórios dos serviços",
        filters: [
          {
            filter_name: "Nome do serviço",
            dropdown: listReportFilterInfos.services,
            value: "Todos",
          },
        ],
        fields: {
          "Serviço": "name",
          "Total de senhas geradas": "amountOfAttendancesGenereted",
          "Total de senhas canceladas": "amountOfCancelledAttendaces",
          "Total de convencionais": "amountOfConventionalAttendances",
          "Todal de preferênciais": "amountOfPreferentialAttendances",
          "Tempo médio da duração total do atendimento": "averageOfTotalTimeAttendancesInMinutes",
          "Tempo médio da execução do atendimento": "averageOfTimeAttendanceInExecutionInMinutes",
          "Relatório gerado no periodo": "dateInterval",
        }
      },
    ]

    commit(types.SET_REPORT_FILTER_INFOS, reports)
  })
}

