import store from "../store";

export default (req) => {
  return ({ status, data }) => {
    if (status == 401) {
      store.dispatch("auth/ActionSignOut");
      window._Vue.$router.push({ name: "login" });
    }
  };
};
